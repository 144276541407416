import { ContentTab, TFunctionString } from "@type/components/common";
import { ChangeLocation } from "../component/changeLocation";
import { EmptyLocation } from "../component/emptyLocation";

export const tabListLocation = (t: TFunctionString): ContentTab[] => [ {
  id: 0,
  title: t("location.tab.changeLocation"),
  content: <ChangeLocation />,
}, {
  id: 1,
  title: t("location.tab.emptyLocation"),
  content: <EmptyLocation />,
} ];