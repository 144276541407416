import GridTable from "@nadavshaar/react-grid-table";
import { ColumnsTableEditing } from "@type/components/common";
import { BasicObject } from "@type/global/common";
import {
  CSSProperties,
  FunctionComponent,
} from "react";
import { useTranslation } from "react-i18next";
import { cellTableEditorStyled } from "./config/cell";

interface Props {
  columns: ColumnsTableEditing[];
  style?: CSSProperties | undefined;
  rows: BasicObject[];
  isPaginated?: boolean;
  isEditable?: boolean;
  showSearch?: boolean;
  onSave?: (data: any[]) => void,
  onRowClick?: ({ rowIndex, data, column, isEdit, event }: any, tableManager: any) => void,
  onSelectedRowsChange?: (selected: any) => void,
  onLoad?: (tableManager: any) => void,
  pageSizes?: number[];
}

const GridTableCustom: FunctionComponent<Props> = ({
  columns,
  style,
  rows,
  isPaginated = true,
  isEditable = false,
  showSearch = true,
  onSave,
  onRowClick,
  onSelectedRowsChange,
  onLoad,
  pageSizes = [ 5, 10, 15 ],
}) => {
  const { t } = useTranslation();
  const cellActions = isEditable ? cellTableEditorStyled(onSave) : [];
  return (
    <div style={style}>
      <GridTable
        columns={[
          ...columns,
          ...cellActions,
        ]}
        rows={rows}
        texts={{
          search: t("common.tableEditing.search"),
          totalRows: t("common.tableEditing.totalRows"),
          rows: t("common.tableEditing.rows"),
          selected: t("common.tableEditing.selected"),
          rowsPerPage: t("common.tableEditing.rowsPerPage"),
          page: t("common.tableEditing.page"),
          of: t("common.tableEditing.of"),
          prev: t("common.tableEditing.prev"),
          next: t("common.tableEditing.next"),
          columnVisibility: t("common.tableEditing.columnVisibility"),
        }}
        isPaginated={isPaginated}
        showSearch={showSearch}
        onRowClick={onRowClick}
        enableColumnsReorder={false}
        onSelectedRowsChange={onSelectedRowsChange}
        onLoad={onLoad}
        pageSizes={pageSizes}
      />
    </div>
  );
};

export default GridTableCustom;
