import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import { InformationUserContext } from '@contexts/user/InformationUser';
import { useContext } from 'react';

interface DataQuery {
  data: any;
  error?: ApolloError | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<any>>;
}

const useLazyQueryCustom = (
  gql: DocumentNode,
): [LazyQueryExecFunction<any, OperationVariables>, DataQuery] => {
  const { setDataUser } = useContext(InformationUserContext);
  const [execRequest, { data, error, loading, refetch }] = useLazyQuery(gql, {
    fetchPolicy: 'network-only',
  });

  if (error?.message === 'FORBIDDEN') {
    setDataUser({
      access_token: null,
      refresh_token: null,
    });
  }

  return [
    execRequest,
    {
      data,
      error,
      loading,
      refetch,
    },
  ];
};

export default useLazyQueryCustom;
