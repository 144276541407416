import { FunctionComponent, PropsWithChildren, useEffect } from "react";

interface Props extends PropsWithChildren {
  title: string;
}

const ComponentConnected: FunctionComponent<Props> = ({ children, title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default ComponentConnected;