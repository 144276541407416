import ComponentConnected from "@components/common/ComponentConnected";
import TabsComponent from "@components/common/TabsComponent";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { tabListLocation } from "./config/tabList";

const Location: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ComponentConnected title={t("location.title")}>
      <TabsComponent content={tabListLocation(t)} />
    </ComponentConnected>
  );
};

export default Location;