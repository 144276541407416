import { Alert, AlertColor, Snackbar } from "@mui/material";
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";

interface Props {
  success?: boolean;
  error?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
  setSuccess?: Dispatch<SetStateAction<boolean>>;
  message: string;
}

const SuccessOrError: FunctionComponent<Props> = ({ success, setSuccess, setError, message, error }) => {
  const [ color, setColor ] = useState<AlertColor>();
  const handleCloseSuccess = () => {
    if (setSuccess) {
      setSuccess(false);
    }

    if (setError) {
      setError(false);
    }
  };

  const colorError = () => {
    setColor("error");
  };

  const colorSuccess = () => {
    setColor("success");
  };

  useEffect(() => {
    colorError();
  }, [ error ]);

  useEffect(() => {
    colorSuccess();
  }, [ success ]);

  return (
    <Snackbar open={success || error} autoHideDuration={4000} onClose={handleCloseSuccess}>
      <Alert onClose={handleCloseSuccess} severity={color}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessOrError;
