import {
  Input,
  SelectComponent,
  SelectMultiple,
  SuccessOrError,
} from '@components/form';
import {
  FunctionComponent,
  useEffect,
  KeyboardEvent,
  useState,
  ChangeEvent,
} from 'react';
import { useForm } from 'react-hook-form';
import styles from '@styles/Location.module.scss';
import { useTranslation } from 'react-i18next';
import { typeTouret } from '@config/data/common';
import { noFilterSelect } from '@config/data/form';
import { getAllAreaNotArchiveWithId } from '@graphql/gql/area/query';
import { Area } from '@graphql/generated/graphql';
import GridTableCustom from '@components/common/GridTableCustom/GridTableCustom';
import { columnTabEmptyLocation } from './config/column';
import { useLazyQueryCustom, useQueryCustom } from '@hooks/request';
import { getLocationsFree } from '@graphql/gql/location/query';
import { CircularProgress } from '@mui/material';
import ModalChangeLocation from '../changeLocation/ModalChangeLocation';
import { getDataForLocation } from '@graphql/gql/drumcable/query';
import { Close } from '@mui/icons-material';

interface FormData {
  areaId: string;
  typeDrumcable: string[];
  lotNumber: string;
}

const EmptyLocation: FunctionComponent = () => {
  const { t } = useTranslation();
  const [typeDrumcableLotNumber, setTypeDrumcableLotNumber] =
    useState<string>('');
  const [locations, setLocations] = useState([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [choiceLocation, setChoiceLocation] = useState<string>('');
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      areaId: '',
      typeDrumcable: [],
      lotNumber: '',
    },
  });
  const { data: listAreas } = useQueryCustom(getAllAreaNotArchiveWithId);
  const { data: listTab, loading, refetch } = useQueryCustom(getLocationsFree);
  const [getInformationData, { data: dataLocation }] =
    useLazyQueryCustom(getDataForLocation);
  const areaId = watch('areaId');
  const typeDrumcable = watch('typeDrumcable');

  const handleOnChangeLotNumber = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const obj = JSON.parse(e.target.value);
      if (obj.lot) {
        setValue('lotNumber', obj.lot);
      }
    } catch (e) {
      return;
    }
  };

  const getDataLotNumber = async (event: KeyboardEvent) => {
    if (event.key === 'Enter' && watch('lotNumber') !== '') {
      const { data } = await getInformationData({
        variables: {
          lotNumber: watch('lotNumber'),
        },
      });

      if (!data) {
        setIsError(true);
        return;
      }

      const typeDrumcableResult = [data?.getOneDrumcableByLot?.type];

      setTypeDrumcableLotNumber(typeDrumcableResult?.toString());

      if (typeDrumcableResult.length) {
        setValue('typeDrumcable', typeDrumcableResult);
      }
      return;
    }

    setTypeDrumcableLotNumber('');
    setValue('typeDrumcable', []);
  };

  useEffect(() => {
    if (areaId && typeDrumcable.length > 0) {
      setLocations(
        listTab?.getLocationsFree?.filter(
          (data) =>
            data.area.id === areaId &&
            typeDrumcable.some((td) => data.typeDrumcable.includes(td)),
        ),
      );
      return;
    }

    if (areaId) {
      setLocations(
        listTab?.getLocationsFree?.filter((data) => data.area.id === areaId),
      );
      return;
    }

    if (typeDrumcable.length > 0) {
      setLocations(
        listTab?.getLocationsFree?.filter((data) =>
          typeDrumcable.some((td) => data.typeDrumcable.includes(td)),
        ),
      );
      return;
    }

    setLocations(listTab?.getLocationsFree);
  }, [areaId, typeDrumcable, listTab]);

  useEffect(() => {
    if (openSuccess) {
      refetch();
    }
  }, [openSuccess]);

  return (
    <div>
      <SuccessOrError
        error={isError}
        setError={setIsError}
        message={t('location.error.unknownLot')}
      />
      <SuccessOrError
        success={openSuccess}
        setSuccess={setOpenSuccess}
        message={t('location.success.changeLocation')}
      />
      <ModalChangeLocation
        setOpenModal={setOpenModal}
        openModal={openModal}
        lotNumberData={dataLocation?.getOneDrumcableByLot}
        setOpenSuccess={setOpenSuccess}
        locationDefaultChoice={choiceLocation}
      />
      <div className={styles.containerFilterTabChangeLocation}>
        <div className={styles.containerSelect}>
          <div className={styles.inputWithTypeDrumcable}>
            <Input
              control={control}
              errors={errors}
              name="lotNumber"
              label={t('location.lotNumber') satisfies string}
              onKeyPress={getDataLotNumber}
              disabled={loading}
              onChangeCustom={handleOnChangeLotNumber}
              InputProps={{
                endAdornment: watch('lotNumber') ? (
                  <Close onClick={() => setValue('lotNumber', '')} />
                ) : (
                  ''
                ),
              }}
            />
            {typeDrumcableLotNumber ? (
              <span>{typeDrumcableLotNumber}</span>
            ) : (
              ''
            )}
          </div>
          <div className={styles.select}>
            <SelectComponent
              control={control}
              name="areaId"
              items={[
                noFilterSelect(t, ''),
                ...(listAreas?.getAllAreaNotArchive?.map((area: Area) => ({
                  value: area.id,
                  content: area.area,
                })) ?? []),
              ]}
              label={t('location.allAreas') satisfies string}
            />
          </div>
          <div className={styles.select}>
            <SelectMultiple
              getValues={getValues}
              setValue={setValue}
              name="typeDrumcable"
              items={typeTouret}
              label={t('location.drumCable') satisfies string}
              errors={{}}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <GridTableCustom
          columns={columnTabEmptyLocation(t)}
          rows={locations}
          showSearch={false}
          onRowClick={({ data }) => {
            if (typeDrumcableLotNumber) {
              setOpenModal(true);
              setChoiceLocation(data?.locationCode);
            }
          }}
        />
      )}
    </div>
  );
};

export default EmptyLocation;
