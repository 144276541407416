import { gql } from "@apollo/client";

export const getAllAreaWithId = gql(`
    query {
      getAllArea {
        id
        area
      }
    }
`);

export const getAllAreaNotArchiveWithId = gql(`
    query {
      getAllAreaNotArchive {
        area
        id
      }
    }
`);
