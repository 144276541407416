import {
  ApolloError,
  DocumentNode,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { InformationUserContext } from '@contexts/user/InformationUser';
import { useContext } from 'react';

interface ReturnFunc {
  data: any;
  error?: ApolloError | undefined;
  loading: boolean;
  refetch: any;
}

const useQueryCustom = (
  gql: DocumentNode,
  options?: QueryHookOptions<any, any>,
): ReturnFunc => {
  const { setDataUser } = useContext(InformationUserContext);
  const { data, error, loading, refetch } = useQuery(gql, {
    fetchPolicy: 'network-only',
    ...options,
  });

  if (error?.message === 'FORBIDDEN') {
    setDataUser({
      access_token: null,
      refresh_token: null,
    });
  }

  return {
    data,
    error,
    loading,
    refetch,
  };
};

export default useQueryCustom;
