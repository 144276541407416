import { SelectComponent } from '@components/form';
import { FunctionComponent, useEffect } from 'react';
import styles from '@styles/Location.module.scss';
import { useTranslation } from 'react-i18next';
import { Location } from '@graphql/generated/graphql';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormSelectItem } from '@type/global/common';
import { LocationData } from '@type/schema/location';

interface Props {
  control: Control<any>;
  watch: UseFormWatch<any>;
  locations: FormSelectItem[];
  setValue: UseFormSetValue<any>;
  allLocationCode: LocationData[];
}

const SelectLocation: FunctionComponent<Props> = ({
  control,
  watch,
  locations,
  setValue,
  allLocationCode,
}) => {
  const { t } = useTranslation();
  const location = watch('location');

  useEffect(() => {
    const splitLocation = location.split('-');

    if (splitLocation.at(0) === 'ZD') {
      setValue('area', 'ZD');
      return;
    }

    if (location !== '') {
      const choiceLocation = allLocationCode?.find(
        (locationData: Location) => locationData.locationCode === location,
      );
      setValue('area', choiceLocation?.areaId);
    }
  }, [location, allLocationCode]);

  return (
    <div className={`${styles.sizeInput} ${styles.pushInput}`}>
      <SelectComponent
        control={control}
        name="location"
        items={locations}
        label={t('location.modal.input.label.location') as string}
      />
    </div>
  );
};

export default SelectLocation;
