import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { Menu } from "@mui/icons-material";
import styles from "@styles/Common.module.scss";
import { ListLinkNavbar } from "./components";

const AppBarComponent: FunctionComponent = () => {
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false);
 
  const handleDrawerToggle = () => {
    setOpenDrawer((prv) => !prv);
  };
 
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <AppBar position="fixed" component="nav">
      <Toolbar className={styles.ToolbarNavbarG}>
        <IconButton
          edge="start"
          onClick={handleDrawerToggle}
        >
          <Menu />
        </IconButton>
        <Typography className={styles.title}>
          {document.title}
        </Typography>
        <Drawer
          open={openDrawer}
          onClose={handleCloseDrawer}
        >
          <ListLinkNavbar handleCloseDrawer={handleCloseDrawer} />
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;