import { RoutesConnected } from "@config/routes";
import { TFunction } from "i18next";

interface ListLink {
  title: string;
  link: string;
  isDivider: boolean;
  isLogout: boolean;
}

export const listLinkNavbar = (t: TFunction): ListLink[] => [ {
  title: t("common.navbar.link.location"),
  link: RoutesConnected.LOCATION,
  isDivider: true,
  isLogout: false,
}, 
// {
//   title: t("common.navbar.link.driver"),
//   link: "",
//   isDivider: false,
//   isLogout: false,
// },
// {
//   title: t("common.navbar.link.received"),
//   link: "",
//   isDivider: false,
//   isLogout: false,
// },
// {
//   title: t("common.navbar.link.dispatch"),
//   link: "",
//   isDivider: false,
//   isLogout: false,
// },
{
  title: t("common.navbar.link.cut"),
  link: "",
  isDivider: false,
  isLogout: false,
},
// {
//   title: t("common.navbar.link.floorPrepare"),
//   link: "",
//   isDivider: false,
//   isLogout: false,
// },
// {
//   title: t("common.navbar.link.dispatchPrepare"),
//   link: "",
//   isDivider: false,
//   isLogout: false,
// },
// {
//   title: t("common.navbar.link.inventory"),
//   link: "",
//   isDivider: true,
//   isLogout: false,
// },
// {
//   title: t("common.navbar.link.anomaly"),
//   link: "",
//   isDivider: true,
//   isLogout: false,
// },
{
  title: t("common.navbar.link.logout"),
  link: "/login",
  isDivider: false,
  isLogout: true,
} ];