import { SelectComponent } from '@components/form';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styles from '@styles/Location.module.scss';
import { FormSelectItem } from '@type/global/common';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Location } from '@graphql/generated/graphql';
import { zdsLocations } from '@utils/utils';
import { LocationData } from '@type/schema/location';

interface Props {
  control: Control<any>;
  allLocationCode: LocationData[];
  watch: UseFormWatch<any>;
  setLocations: Dispatch<SetStateAction<FormSelectItem[]>>;
}

interface ListArea {
  content: string;
  value: number | string;
}

const SelectArea: FunctionComponent<Props> = ({
  control,
  allLocationCode,
  watch,
  setLocations,
}) => {
  const { t } = useTranslation();
  const area = watch('area');
  const [locationArea, setLocationsArea] = useState<ListArea[]>([]);

  useEffect(() => {
    if (area === 'ZD') {
      setLocations(zdsLocations());
      return;
    }

    if (area) {
      const listFilterLocation = allLocationCode?.filter(
        (location: Location) => location.areaId === parseInt(area),
      );
      setLocations(
        listFilterLocation?.map((location: Location) => ({
          value: location.locationCode,
          content: location.locationCode,
        })) ?? [],
      );
      return;
    }

    return setLocations([
      ...(allLocationCode?.map((location: Location) => ({
        value: location.locationCode,
        content: location.locationCode,
      })) ?? []),
      ...zdsLocations(),
    ]);
  }, [area]);

  useEffect(() => {
    const locationsSet = new Set<number>();
    const locations = [];
    allLocationCode?.forEach((location) => {
      if (!locationsSet.has(location.areaId)) {
        locations.push({
          value: location.area.id,
          content: location.area.area,
        });
      }
      locationsSet.add(location.area.id);
    });
    setLocationsArea(locations);
  }, [allLocationCode]);

  return (
    <div className={styles.sizeInput}>
      <SelectComponent
        control={control}
        name="area"
        items={
          [
            {
              content: t('location.noArea'),
              value: undefined,
            },
            ...locationArea,
            { value: 'ZD', content: 'ZD' },
          ] ?? []
        }
        label={t('location.modal.input.label.area') as string}
      />
    </div>
  );
};

export default SelectArea;
