import { Input, SuccessOrError } from '@components/form';
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from '@styles/Location.module.scss';
import { Button } from '@mui/material';
import ModalChangeLocation from './ModalChangeLocation';
import { useLazyQueryCustom } from '@hooks/request';
import { Close, Warning } from '@mui/icons-material';
import { getDataForLocation } from '@graphql/gql/drumcable/query';

const ChangeLocation: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEmptyLot, setOpenEmptyLot] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [getInformationData, { data: dataLocation, refetch }] =
    useLazyQueryCustom(getDataForLocation);
  const lotNumber = watch('lotNumber');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOnChangeLotNumber = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const obj = JSON.parse(e.target.value);
      if (obj.lot) {
        setValue('lotNumber', obj.lot);
      }
    } catch (e) {
      return;
    }
  };

  const getDataLotNumber = async (event: KeyboardEvent) => {
    if (event.key === 'Enter' && lotNumber !== '') {
      const { data } = await getInformationData({
        variables: {
          lotNumber,
        },
      });

      if (!data?.getOneDrumcableByLot?.location) {
        setOpenEmptyLot(true);
      } else {
        setOpenEmptyLot(false);
      }
    }
  };

  useEffect(() => {
    if (openSuccess) {
      refetch();
    }
  }, [openSuccess]);

  return (
    <>
      <div className={styles.containerBtnScanAndSearchLot}>
        <SuccessOrError
          success={openSuccess}
          setSuccess={setOpenSuccess}
          message={t('location.success.changeLocation')}
        />
        <Input
          control={control}
          errors={errors}
          name="lotNumber"
          label={t('location.lotNumber') satisfies string}
          size="small"
          onKeyPress={getDataLotNumber}
          onChangeCustom={handleOnChangeLotNumber}
          InputProps={{
            endAdornment: lotNumber ? (
              <Close onClick={() => setValue('lotNumber', '')} />
            ) : (
              ''
            ),
          }}
        />
      </div>
      <div className={styles.containerLocationDataInformation}>
        {dataLocation?.getOneDrumcableByLot?.location && (
          <>
            <div className={styles.firstContainer}>
              <span className={styles.firstTitle}>
                {dataLocation?.getOneDrumcableByLot?.location
                  ?.split('-')
                  ?.at(0) === 'INT'
                  ? t('location.internal')
                  : t('location.external')}
              </span>
              <span className={`${styles.firstTitle} ${styles.colorGreen}`}>
                {dataLocation?.getOneDrumcableByLot?.location}
              </span>
            </div>
            <div className={styles.secondContainer}>
              <span className={styles.title}>
                {dataLocation?.getOneDrumcableByLot?.articleCode}
              </span>
              <span className={styles.bigSize}>
                {dataLocation?.getOneDrumcableByLot.quantity}m
              </span>
              <span>{dataLocation?.getOneDrumcableByLot?.type}</span>
            </div>
            <div className={styles.containerBtnChangeLocation}>
              <Button variant="contained" onClick={handleOpenModal}>
                {t('location.btn.changeLocation')}
              </Button>
            </div>
            <ModalChangeLocation
              setOpenModal={setOpenModal}
              openModal={openModal}
              lotNumberData={dataLocation?.getOneDrumcableByLot}
              setOpenSuccess={setOpenSuccess}
            />
          </>
        )}
        {openEmptyLot && (
          <div className={styles.containerUnknownLot}>
            <Warning />
            <h2>{t('location.error.unknownLot')}</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default ChangeLocation;
