import { FormSelectItem, TypeTouret } from "@type/global/common";

export const typeTouret: FormSelectItem[] = [
  {
    value: TypeTouret.A,
    content: "A",
  },
  {
    value: TypeTouret.B,
    content: "B",
  },
  {
    value: TypeTouret.C,
    content: "C",
  },
  {
    value: TypeTouret.D,
    content: "D",
  },
  {
    value: TypeTouret.E,
    content: "E",
  },
  {
    value: TypeTouret.F,
    content: "F",
  },
  {
    value: TypeTouret.G,
    content: "G",
  },
  {
    value: TypeTouret.H,
    content: "H",
  },
  {
    value: TypeTouret.I,
    content: "I",
  },
  {
    value: TypeTouret.X,
    content: "X",
  },
  {
    value: TypeTouret.Q,
    content: "Q",
  },
];