import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { BasicObject, FormSelectItem } from '@type/global/common';
import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import styles from '@styles/Common.module.scss';

interface Props {
  control: Control<any, BasicObject>;
  name: string;
  defaultValue?: number | string;
  label?: string;
  items: FormSelectItem[];
  required?: boolean;
  errors?: BasicObject;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const SelectComponent: FunctionComponent<Props> = ({
  control,
  name,
  defaultValue = '',
  label = '',
  items,
  required = false,
  errors,
  disabled = false,
  size = 'small',
}) => {
  const values = items.map((i) => i.value);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl className={styles.containerSelectComponentG}>
          <InputLabel
            size={size === 'small' ? 'small' : 'normal'}
            id={`select-eleo-${label}`}
          >
            {label}
          </InputLabel>
          <Select
            id={`select-eleo-${label}`}
            {...field}
            name={name}
            label={label}
            value={values.includes(field.value) ? field.value : ''}
            defaultValue={
              field.value ?? (items.length && items[0].value) ?? null
            }
            disabled={disabled}
            error={!!errors && !!errors[name]}
            required={required}
            size={size}
          >
            {items.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.content}
              </MenuItem>
            ))}
          </Select>
          {!!errors && !!errors[name] && (
            <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>
              {errors[name].message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
