import { InformationUserContext } from "@contexts/user/InformationUser";
import { Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import localforage from "localforage";
import { Fragment, FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { listLinkNavbar } from "../config/listLink";

interface Props {
  handleCloseDrawer: () => void;
}

const ListLinkNavbar: FunctionComponent<Props> = ({ handleCloseDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setDataUser } = useContext(InformationUserContext);
  
  const handleChange = async (link: string, isLogout?: boolean) => {
    handleCloseDrawer();
    navigate(link);
    if (isLogout) {
      await localforage.removeItem("@refreshToken");
      await localforage.removeItem("@accessToken");
      setDataUser({
        access_token: null,
        refresh_token: null,
      });
    }
  };
  
  return (
    <div>
      <List>
        {listLinkNavbar(t).map(({ title, isDivider, link, isLogout }, index) => (
          <Fragment key={index}>
            <ListItem disablePadding onClick={() => handleChange(link, isLogout)}>
              <ListItemButton>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
            {isDivider && <Divider />}
          </Fragment>
        ))}
      </List>
     
    </div>
  );
};

export default ListLinkNavbar;