export interface BasicObject {
  [key: string]: any
}

export interface FormSelectItem {
  value: any
  content: string
}

export const enum TypeTouret {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  X = "X",
  Q = "Q",
}