import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { RetryLink } from '@apollo/client/link/retry';

const { REACT_APP_SERVER_URL, REACT_APP_SERVER_TOKEN } = process.env;

const cache = new InMemoryCache();

persistCache({
  cache,
  storage: new LocalForageWrapper(localforage),
}).then();

const httpLink: HttpLink = new HttpLink({
  uri: REACT_APP_SERVER_URL,
});

const authLink: ApolloLink = setContext(async (_, { headers }) => {
  const token = await localforage.getItem('@accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ?? REACT_APP_SERVER_TOKEN,
    },
  };
});

const getNewToken = async () => {
  const refreshToken = await localforage.getItem('@refreshToken');
  const response = await fetch(REACT_APP_SERVER_URL ?? '', {
    headers: {
      authorization: REACT_APP_SERVER_TOKEN ?? '',
      'content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query: `mutation refreshToken($refreshToken: String!) {
        refreshToken(data: {
          refreshToken: $refreshToken
        }) {
          access_token
        }
      }
    `,
      variables: {
        refreshToken,
      },
    }),
  });

  const res = await response.json();

  if (res.data) {
    await localforage.setItem(
      '@accessToken',
      res.data.refreshToken.access_token,
    );
    return res.data.refreshToken.access_token;
  }

  await localforage.removeItem('@refreshToken');
  await localforage.removeItem('@accessToken');
  window.dispatchEvent(new Event('logout', { cancelable: false }));
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions.describe === 'JWT_EXPIRED') {
      return fromPromise(
        getNewToken().catch(() => {
          return;
        }),
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: accessToken,
            },
          });
          return forward(operation);
        });
    }
  }
});

const retryLink = new RetryLink();

const clientGraphQL = new ApolloClient({
  link: from([authLink, errorLink, retryLink, httpLink]),
  cache,
});

export default clientGraphQL;
