import {
  Cancel,
  Edit,
  Save,
} from "@mui/icons-material";
import Button from "@mui/material/Button";

interface CellRender {
  tableManager: any;
  data: any;
}

export const cellTableEditorStyled = (onSave?: any) => [ {
  id: "buttons",
  width: "max-content",
  pinned: true,
  sortable: false,
  resizable: false,
  cellRenderer: ({
    tableManager,
    data,
  }: CellRender) => (
    <div>
      <Button
        title="Edit"
        onClick={(e) => {
          e.stopPropagation();
          tableManager.rowEditApi.setEditRowId(data.id);
        }}
      >
        <Edit/>
      </Button>
    </div>
  ),
  editorCellRenderer: ({
    tableManager,
    data,
  }: CellRender) => (
    <div>
      <Button
        title="Cancel"
        color={"error"}
        onClick={(e) => {
          e.stopPropagation();
          tableManager.rowEditApi.setEditRowId(null);
        }}
      >
        <Cancel/>
      </Button>
      <Button
        title="Save"
        onClick={(e) => {
          e.stopPropagation();
          const rowsClone = [ ...tableManager.rowsApi.rows ];
          const updatedRowIndex = rowsClone.findIndex(
            (r) => r.id === data.id,
          );
          rowsClone[updatedRowIndex] = data;
          onSave && onSave([ ...rowsClone ]);
          tableManager.rowEditApi.setEditRowId(null);
        }}
      >
        <Save/>
      </Button>
    </div>
  ),
} ];
