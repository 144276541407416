import { gql } from '@apollo/client';

export const getDataForLocation = gql(`
  query getOneDrumcableByLot($lotNumber: String){
  getOneDrumcableByLot(data: {lot: $lotNumber}) {
    quantity
    articleCode
    lot
    location
    type
  }
}
`);

export const getDrumcablesByLocation = gql(`
query getDrumcablesByLocation($location: String!) {
  getDrumcablesByLocation(data: {location: $location}) {
    quantity
    articleCode
    lot
    location
  }
}
`);
