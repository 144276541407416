import { commonTranslate } from "./common";
import { locationTranslate } from "./location";
import { userTranslate } from "./user";

export const resourcesFr = {
  fr: {
    translation: {
      common: commonTranslate,
      user: userTranslate,
      location: locationTranslate,
    },
  },
};