import { useMutation } from "@apollo/client";
import { Input, SuccessOrError } from "@components/form";
import { RoutesConnected } from "@config/routes";
import { InformationUserContext } from "@contexts/user/InformationUser";
import { login } from "@graphql/gql/user/query";
import { Button } from "@mui/material";
import localforage from "localforage";
import { FunctionComponent, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styles from "@styles/User.module.scss";
import { useTranslation } from "react-i18next";
import logo from "../../img/eleo.png";
import { LoginMutation, LoginMutationVariables } from "@graphql/generated/graphql";
import { parseError } from "@utils/request";
import { ErrorMessageRequest } from "@type/errors/request";

const Login: FunctionComponent = () => {
  const [ loginM ] = useMutation(login);
  const { setDataUser } = useContext(InformationUserContext);
  const navigate = useNavigate();
  const { control, formState: { errors }, handleSubmit } = useForm<LoginMutationVariables>();
  const { t } = useTranslation();
  const [ isError, setIsError ] = useState<boolean>(false);
  const [ message, setMessage ] = useState<string>("");

  const sendData = async ({ email, password }: LoginMutationVariables) => {
    await loginM({
      variables: {
        email,
        password,
      },
      onCompleted: async (s: LoginMutation) => {
        const { access_token, refresh_token } = s.login;
        await localforage.setItem("@accessToken", access_token);
        await localforage.setItem("@refreshToken", refresh_token);
        setDataUser({
          access_token,
          refresh_token,
        });
        navigate(RoutesConnected.INDEX);
      },
      onError: (error) => {
        setMessage("");
        const errorLogin = parseError(error);

        if (errorLogin.graphQLErrors[0].extensions.describe === ErrorMessageRequest.BAD_CREDENTIAL) {
          setMessage(t("common.error.badCredential") as string);
        } else {
          setMessage(t("common.error.unknown") as string);
        }
        setIsError(true);
      },
    });
  };
  
  return (
    <div className={styles.containerLoginForm}>
      <SuccessOrError error={isError} setError={setIsError} message={message} />
      <img src={logo} alt="Logo" height={50} />
      <div className={styles.pushItemTop}>
        <Input name="email" control={control} errors={errors} label={t("user.login.email") as string} />
      </div>
      <div className={styles.pushItemTop}>
        <Input name="password" control={control} errors={errors} type="password" label={t("user.login.password") as string} />
      </div>
      <div className={styles.pushItemTop}>
        <Button 
          variant="contained" 
          onClick={handleSubmit(sendData)}
        >
          {t("user.login.login")}
        </Button>
      </div>
    </div>
  );
};

export default Login;