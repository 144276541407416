export const locationTranslate = {
  scanLot: 'Scanner le lot',
  lotNumber: 'N° de lot',
  allAreas: 'Toutes les zones',
  drumCable: 'Tourets',
  title: 'Emplacement',
  noArea: 'Pas de zone',
  external: 'EXTERNE',
  internal: 'INTERNE',
  tab: {
    changeLocation: 'Changer Empl.',
    emptyLocation: 'Empl vide',
  },
  btn: {
    changeLocation: "Changer l'emplacement",
  },
  modal: {
    title: 'Scanner ou sélectionner le nouvel emplacement',
    input: {
      label: {
        area: 'Zone',
        location: 'Emplacement',
      },
    },
    btn: {
      validate: "Valider l'emplacement",
    },
  },
  error: {
    unknownLot: 'Lot introuvable',
    notEmptyLocation: 'EMPLACEMENT OCCUPE',
  },
  success: {
    changeLocation: 'Changement effectué avec succès',
  },
  tabEmpty: {
    label: {
      area: 'Zone',
      range: 'Allée',
      location: 'Emplacement',
    },
  },
};
