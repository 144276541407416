import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as SelectComponent,
} from "@mui/material";
import { BasicObject, FormSelectItem } from "@type/global/common";
import { FunctionComponent, useEffect } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

interface Props {
  items: FormSelectItem[];
  defaultValue?: number[];
  name: string;
  label?: string;
  errors: BasicObject;
  required?: boolean;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  variant?: "standard" | "filled" | "outlined";
  style?: BasicObject;
  disabled?: boolean;
  nbDisplayedItemInList?: number;
  size?: "small" | "medium";
}

const SelectMultiple: FunctionComponent<Props> = ({
  items,
  defaultValue,
  name,
  label = "",
  errors,
  required = false,
  setValue,
  getValues,
  variant = "outlined",
  style,
  disabled = false,
  nbDisplayedItemInList = 4,
  size = "small",
}) => {
  const ITEM_HEIGHT = 54;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * nbDisplayedItemInList + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const changeValue = (event: { target: { value: any } }) => {
    setValue(name, event.target.value, { shouldValidate: true });
  };

  useEffect(() => {
    setValue(name, []);
  }, [ name, setValue ]);

  return (
    <FormControl fullWidth>
      <InputLabel size={size === "small" ? "small" : "normal"} id={`select-eleo-${label}`}>{label}</InputLabel>
      <SelectComponent
        multiple
        value={getValues(name) ?? defaultValue ?? []}
        onChange={changeValue}
        variant={variant}
        style={style}
        error={!!errors[name]}
        required={required}
        disabled={disabled}
        size="small"
        renderValue={(selected) => {
          return selected
            .map((s: string) => {
              const content: any = items.find((aa) => aa.value === s);
              return content?.content ?? "";
            })
            .join(", ");
        }}
        MenuProps={MenuProps}
      >
        {items.map((content: any, index) => [
          <MenuItem key={index} value={content.value}>
            <Checkbox checked={(getValues(name) ?? []).indexOf(content.value) > -1} />
            <ListItemText primary={content.content} />
          </MenuItem>,
        ])}
      </SelectComponent>
      {!!errors && !!errors[name] && (
        <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
          {errors[name].message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMultiple;
