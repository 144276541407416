import { TextField } from '@mui/material';
import { BasicObject } from '@type/global/common';
import {
  FunctionComponent,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
} from 'react';
import { Control, Controller } from 'react-hook-form';
import styles from '@styles/Common.module.scss';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
interface Props {
  control: Control<any, BasicObject>;
  name: string;
  defaultValue?: unknown;
  label?: string;
  placeholder?: string;
  errors: BasicObject;
  required?: boolean;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  size?: 'small' | 'medium';
  onKeyPress?: KeyboardEventHandler<any> | undefined;
  onChangeCustom?: StandardInputProps['onChange'];
  InputProps?: Partial<StandardInputProps>;
}

const Input: FunctionComponent<Props> = ({
  control,
  name,
  defaultValue = '',
  label = '',
  errors,
  required = false,
  placeholder = '',
  disabled = false,
  type = 'text',
  size = 'small',
  onKeyPress,
  onChangeCustom,
  InputProps,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          required={required}
          label={label}
          id={name}
          placeholder={placeholder}
          error={!!errors && errors[name]}
          disabled={disabled}
          type={type}
          size={size}
          onKeyPress={onKeyPress}
          className={styles.inputStyledG}
          InputProps={InputProps}
          onChange={(e) => {
            field.onChange(e);
            if (onChangeCustom) {
              onChangeCustom(e);
            }
          }}
        />
      )}
    />
  );
};

export default Input;
