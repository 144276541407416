import { InformationUserContextInterface, InformationUserData } from "@type/contexts/user";
import { createContext, FunctionComponent, PropsWithChildren, useState } from "react";

export const InformationUserContext = createContext<InformationUserContextInterface>({
  dataUser: {
    access_token: null,
    refresh_token: null,
  },
  setDataUser: () => {
    // empty body
  },
});

const InformationUser: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [ dataUser, setDataUser ] = useState<InformationUserData>({
    access_token: null,
    refresh_token: null,
  });

  return (
    <InformationUserContext.Provider value={{ dataUser, setDataUser }}>
      {children}
    </InformationUserContext.Provider>
  );
};

export default InformationUser;
