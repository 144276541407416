import { gql } from '@apollo/client';

export const updateLocationFromPWA = gql(`
  mutation updateLocationFromPWA($location: String!, $lot: String!) {
    updateLocationFromPWA(
      data: {
        lot: $lot,
        location: $location
       }
      )
    }
`);
