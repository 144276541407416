import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import styles from "@styles/Common.module.scss";
import { Dispatch, FunctionComponent, ReactNode, SetStateAction } from "react";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  ButtonAction?: ReactNode;
  children?: ReactNode;
  title: string;
}

const DialogComponent: FunctionComponent<Props> = ({
  open,
  setOpen,
  ButtonAction,
  children,
  title,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <DialogTitle className={styles.commonDialogContainerTitleG}>
        {title}
        <CloseIcon
          className={styles.commonDialogButtonCloseTitleG}
          onClick={() => {
            setOpen(false);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div className={styles.commonDialogContainerContentG}>{children}</div>
      </DialogContent>
      <DialogActions className={styles.commonDialogContainerButtonG}>{ButtonAction}</DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
