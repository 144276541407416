import { Location } from "@pages/location";

interface RouteConnectedArray {
  path: RoutesConnected;
  element: JSX.Element;
}

export enum RoutesConnected {
  INDEX = "/",
  LOCATION = "/emplacement",
}

export const routeConnected: RouteConnectedArray[] = [
  {
    path: RoutesConnected.INDEX,
    element: <></>,
  },
  {
    path: RoutesConnected.LOCATION,
    element: <Location />,
  },
];
