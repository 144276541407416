import { gql } from '@apollo/client';

export const getAllLocationCode = gql(`
  query {
    getAllLocation {
      locationCode
      areaId
      area {
        area
      }
    }
  }
`);

export const getLocationsFree = gql(`
    query getLocationsFree {
      getLocationsFree {
        area {
          area
          id
        }
        range
        locationCode
        typeDrumcable
      }
    }
`);

export const getAllLocationNotArchive = gql(`
    query getAllLocationNotArchive($typeDrumcable: [String!]!) {
      getAllLocationNotArchive(data: {typeDrumcable: $typeDrumcable}) {
        locationCode
        areaId
        lotAccepted
        area {
          area
          id
        }
      }
    }
`);
