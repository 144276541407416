import { ColumnsTableEditing, TFunctionString } from "@type/components/common";

export const columnTabEmptyLocation = (t: TFunctionString): ColumnsTableEditing[] => [ {
  field: "area",
  id: "area",
  label: t("location.tabEmpty.label.area"),
  cellRenderer: ({ data }) => <span>{data?.area?.area ?? ""}</span>,
},{
  field: "range",
  id: "range",
  label: t("location.tabEmpty.label.range"),
},{
  field: "locationCode",
  id: "locationCode",
  label: t("location.tabEmpty.label.location"),
} ];