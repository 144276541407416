import Navbar from '@components/common/navbar';
import { routeConnected, RoutesConnected } from '@config/routes';
import { InformationUserContext } from '@contexts/user/InformationUser';
import localforage from 'localforage';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './user/Login';
import styles from '@styles/Common.module.scss';

const App = (): ReactElement => {
  const { dataUser, setDataUser } = useContext(InformationUserContext);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleCheckedRefreshToken = async () => {
    const refresh_token = await localforage.getItem<string | null>(
      '@refreshToken',
    );
    const access_token = await localforage.getItem<string | null>(
      '@accessToken',
    );
    setDataUser({
      access_token,
      refresh_token,
    });
    setIsFirstRender(false);
  };

  const initApp = () => {
    window.addEventListener('logout', () => {
      window.location.replace(RoutesConnected.INDEX);
    });
  };

  useEffect(() => {
    handleCheckedRefreshToken();
    initApp();
  }, []);

  return (
    <BrowserRouter>
      {!isFirstRender && (
        <>
          {dataUser.refresh_token ? (
            <>
              <Navbar />
              <main className={styles.containerMainG}>
                <Routes>
                  {routeConnected.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                </Routes>
              </main>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
