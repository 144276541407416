import React from "react";
import ReactDOM from "react-dom/client";
import { EntryPoint } from "@pages/index";
import { register as registerWorker } from "./serviceWorkerRegistration";
import { ApolloProvider } from "@apollo/client";
import { clientGraphQL } from "./graphql/client";
import "@config/i18n/i18nConfig";
import { createTheme, ThemeProvider } from "@mui/material";
import InformationUser from "@contexts/user/InformationUser";
import "@styles/global.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#14A7E0",
    },
  },
});

root.render(
  <React.StrictMode>
    <InformationUser>
      <ApolloProvider client={clientGraphQL}>
        <ThemeProvider theme={theme}>
          <EntryPoint />
        </ThemeProvider>
      </ApolloProvider>
    </InformationUser>
  </React.StrictMode>,
);

registerWorker();
