import { Tab, Tabs } from "@mui/material";
import { FunctionComponent, SyntheticEvent, useState } from "react";
import styles from "@styles/Common.module.scss";
import { ContentTab } from "@type/components/common";

interface Props {
  content: ContentTab[]
}

const TabsComponent: FunctionComponent<Props> = ({ content }) => {
  const [ value, setValue ] = useState<number>(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {content.map((contentTab, index) => (
          <Tab label={contentTab.title} key={index} />
        ))}
      </Tabs>
      <div className={styles.containerChildrenTabsComponentsG}>
        {content.find((contentTab) => contentTab.id === value)?.content}
      </div>
    </>
  );
};

export default TabsComponent;