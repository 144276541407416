import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styles from '@styles/Location.module.scss';
import { Button, CircularProgress } from '@mui/material';
import DialogComponent from '@components/common/DialogComponent';
import { useForm } from 'react-hook-form';
import { getAllLocationNotArchive } from '@graphql/gql/location/query';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SelectArea, SelectLocation } from './form';
import { FormSelectItem } from '@type/global/common';
import { useLazyQueryCustom } from '@hooks/request';
import { updateLocationFromPWA } from '@graphql/gql/drumcable/mutation';
import { getDrumcablesByLocation } from '@graphql/gql/drumcable/query';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenSuccess: Dispatch<SetStateAction<boolean>>;
  lotNumberData: any;
  locationDefaultChoice?: string;
}

const ModalChangeLocation: FunctionComponent<Props> = ({
  openModal,
  setOpenModal,
  lotNumberData,
  setOpenSuccess,
  locationDefaultChoice,
}) => {
  const [getAllLocation, { data: allLocationCode }] = useLazyQueryCustom(
    getAllLocationNotArchive,
  );
  const [locations, setLocations] = useState<FormSelectItem[]>([]);
  const [updateLocationForLocationProviderOrderArticle] = useMutation(
    updateLocationFromPWA,
  );
  const { control, watch, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      area: undefined,
      location: '',
    },
  });
  const [getInformationData, { data: dataLocation, loading }] =
    useLazyQueryCustom(getDrumcablesByLocation);
  const { t } = useTranslation();
  const location = watch('location');
  const choiceActualLocation = allLocationCode?.getAllLocationNotArchive?.find(
    (l) => l.locationCode === location,
  );

  const sendData = async () => {
    await updateLocationForLocationProviderOrderArticle({
      variables: {
        lot: lotNumberData.lot,
        location,
      },
    });

    setOpenModal(false);
    setOpenSuccess(true);
    reset();
  };

  useEffect(() => {
    if (location) {
      getInformationData({
        variables: {
          location,
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (locationDefaultChoice) {
      setValue('location', locationDefaultChoice);
    }
  }, [locationDefaultChoice]);

  useEffect(() => {
    if (lotNumberData) {
      getAllLocation({
        variables: {
          typeDrumcable: [lotNumberData?.type],
        },
      });
    }
  }, [lotNumberData]);

  return (
    <DialogComponent
      title={t('location.modal.title')}
      open={openModal}
      setOpen={setOpenModal}
    >
      <div className={styles.containerLocationModal}>
        <div className={styles.containerInput}>
          <SelectArea
            watch={watch}
            setLocations={setLocations}
            control={control}
            allLocationCode={allLocationCode?.getAllLocationNotArchive}
          />
          <SelectLocation
            control={control}
            watch={watch}
            locations={locations}
            setValue={setValue}
            allLocationCode={allLocationCode?.getAllLocationNotArchive}
          />
        </div>
        {loading ? (
          <CircularProgress />
        ) : dataLocation?.getDrumcablesByLocation?.length > 0 ? (
          <div className={styles.containerInformationData}>
            <span className={`${styles.errorData} ${styles.locationTitle}`}>
              {dataLocation?.getDrumcablesByLocation
                ?.at(0)
                ?.location.split('-')
                .at(0) === 'INT'
                ? t('location.internal')
                : t('location.external')}
            </span>
            <span className={`${styles.areaSize} ${styles.errorData}`}>
              {dataLocation?.getDrumcablesByLocation?.at(0)?.location}
            </span>
            <span className={`${styles.errorData} ${styles.locationTitle}`}>
              {t('location.error.notEmptyLocation') as string}
            </span>
            <span className={styles.errorData}>
              {dataLocation?.getDrumcablesByLocation?.at(0)?.articleCode}
            </span>
            <span className={styles.errorData}>
              {dataLocation?.getDrumcablesByLocation?.at(0)?.quantity}m
            </span>
            <span className={styles.errorData}>
              {`${t('location.lotNumber')} : ${
                dataLocation?.getDrumcablesByLocation?.at(0)?.lot
              }`}
            </span>
          </div>
        ) : (
          <div className={styles.containerInformationData}>
            <span className={`${styles.areaSize} ${styles.colorSuccess}`}>
              {location}
            </span>
          </div>
        )}
        <div className={styles.containerBtnValidate}>
          {/*{console.log(dataLocation?.getDrumcablesByLocation.length)}*/}
          <Button
            disabled={
              location === '' ||
              (choiceActualLocation?.lotAccepted > 1 &&
                dataLocation?.getDrumcablesByLocation.length >
                  choiceActualLocation?.lotAccepted)
            }
            onClick={handleSubmit(sendData)}
            variant="contained"
          >
            {t('location.modal.btn.validate')}
          </Button>
        </div>
      </div>
    </DialogComponent>
  );
};

export default ModalChangeLocation;
