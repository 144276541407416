export const commonTranslate = {
  navbar: {
    link: {
      location: "Emplacement",
      driver: "Cariste",
      received: "Réception",
      dispatch: "Expédition",
      cut: "coupe",
      floorPrepare: "Préparation Etage",
      dispatchPrepare: "Préparation Cmd",
      inventory: "Inventaire",
      anomaly: "anomalie",
      logout: "Déconnexion",
    },
  },
  noFilter: "Pas de filtre",
  error: {
    unknown: "Une erreur est survenue",
    badCredential: "Mauvais identifiant",
  },
  tableEditing: {
    search: "Recherche :", 
    totalRows: "Total :", 
    rows: "Ligne :", 
    selected: "Sélectionner", 
    rowsPerPage: "Ligne par page :", 
    page: "Page :", 
    of: "de", 
    prev: "Précédent", 
    next: "Suivant", 
    columnVisibility: "Colonne visible",
  },
};